import React, { useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { useTheme } from 'emotion-theming';
import { ThemeProvider } from 'emotion-theming';
import Layout from '../layout/Layout';

import { siteTitle } from '../../data/SiteConfig';
import { ThemeContext } from '../theming/ThemeContext';

const CalculateProductiveTime = () => {
  const theme = useTheme();
  const themeContext = useContext(ThemeContext);

  const [hours, setHours] = useState('0');
  const [multiplier, setMultiplier] = useState(1.6);

  const [hoursWorked, minutesWorked] = hours.split(':');

  const hoursInMinutes = hoursWorked * 60;

  const cleanedMinutes = minutesWorked
    ? parseInt(minutesWorked.slice(0, 2), 10)
    : 0;
  const totalMinutes = hoursInMinutes + cleanedMinutes;

  const totalMinutesWithDeltaApplied = totalMinutes * multiplier;

  const totalHours = totalMinutesWithDeltaApplied / 60;

  const [finalHour, minutesInHours] = `${totalHours}`.split('.');

  const finalMinutesWithDecimals = minutesInHours
    ? `0.${minutesInHours}` * 60
    : 0;

  const finalMinutes = Math.round(finalMinutesWithDecimals);

  return (
    <Layout>
      <Helmet
        title={`Calculate your workday based on your producte time | ${siteTitle}`}
      />
      <h1>Calculate your workday based on your producte time</h1>

      <ThemeProvider
        theme={{
          ...themeContext.theme,
          containerSize: 'var(--container-size-toc)',
        }}
      >
        <form
          css={{
            background: theme.navigationBg,
            padding: '28px',
            borderRadius: '4px',
          }}
        >
          <div>
            <label htmlFor='hours'>Productive hours worked (4:45)</label>
            <input
              id='hours'
              value={hours}
              onChange={event => setHours(event.target.value)}
              css={{ borderRadius: '4px', display: 'block', width: '80px' }}
            />
          </div>

          <div css={{ margin: '20px 0 0 0' }}>
            <label htmlFor='delta'>Multiplier (e.g 60% = 1.6)</label>
            <div>
              <input
                id='delta'
                type='number'
                value={multiplier}
                onChange={event => setMultiplier(event.target.value)}
                css={{ borderRadius: '4px', width: '80px' }}
              />
            </div>
          </div>

          <b>Total hours worked: </b>
          <div css={{ display: 'inline-block', margin: '20px 0 6px' }}>
            <b css={{ fontSize: 30 }}>{finalHour}</b> hours,{' '}
            <b css={{ fontSize: 30 }}>{finalMinutes}</b> minutes
          </div>
        </form>
      </ThemeProvider>
    </Layout>
  );
};

export default CalculateProductiveTime;
